var map;
var geocoder;
var markers = [];
function initMap() {
    //Check if added #map element
    //if( undefined == jQuery('#map') || jQuery('#map').length > 0 )
      //  return false;

    // Styles a map in night mode.
    map = new google.maps.Map(document.getElementById('map'), {
        center: {lat: 41.0052363, lng: 28.8717545},
        zoom: 9,
       /* styles: [
            {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
            {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
            {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
            {
                featureType: 'administrative.locality',
                elementType: 'labels.text.fill',
                stylers: [{color: '#d59563'}]
            },
            {
                featureType: 'poi',
                elementType: 'labels.text.fill',
                stylers: [{color: '#d59563'}]
            },
            {
                featureType: 'poi.park',
                elementType: 'geometry',
                stylers: [{color: '#263c3f'}]
            },
            {
                featureType: 'poi.park',
                elementType: 'labels.text.fill',
                stylers: [{color: '#6b9a76'}]
            },
            {
                featureType: 'road',
                elementType: 'geometry',
                stylers: [{color: '#38414e'}]
            },
            {
                featureType: 'road',
                elementType: 'geometry.stroke',
                stylers: [{color: '#212a37'}]
            },
            {
                featureType: 'road',
                elementType: 'labels.text.fill',
                stylers: [{color: '#9ca5b3'}]
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry',
                stylers: [{color: '#746855'}]
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry.stroke',
                stylers: [{color: '#1f2835'}]
            },
            {
                featureType: 'road.highway',
                elementType: 'labels.text.fill',
                stylers: [{color: '#f3d19c'}]
            },
            {
                featureType: 'transit',
                elementType: 'geometry',
                stylers: [{color: '#2f3948'}]
            },
            {
                featureType: 'transit.station',
                elementType: 'labels.text.fill',
                stylers: [{color: '#d59563'}]
            },
            {
                featureType: 'water',
                elementType: 'geometry',
                stylers: [{color: '#17263c'}]
            },
            {
                featureType: 'water',
                elementType: 'labels.text.fill',
                stylers: [{color: '#515c6d'}]
            },
            {
                featureType: 'water',
                elementType: 'labels.text.stroke',
                stylers: [{color: '#17263c'}]
            }
        ]*/
    });
    geocoder = new google.maps.Geocoder();
// Create markers.
    features.forEach(function(feature) {
     //   geocoder.geocode( { 'address': feature.address}, function(results, status) {
          //  console.log(status);
          //  if (status == 'OK') {
                var marker = new google.maps.Marker({
                    //var marker = new MarkerWithLabel({
                    position: feature.latlng,
                    icon: feature.icon,
                    title: feature.label,
                    map: map,
                    labelContent: feature.label,
                    labelAnchor: new google.maps.Point(78, 102),
                    labelClass: "labels",
                    labelInBackground: false
                });
                var content = document.createElement('div');
                content.innerHTML = "<strong style='color: #000;'>"+feature.label+"</strong>";

                var infowindow = new google.maps.InfoWindow({
                    content: content
                });

               // markers[feature.post_id] = [infowindow, marker];

                // google.maps.event.addListener(marker, 'click', function() {
                //     infowindow.open(map, marker);
                // });

            //}
        //});
    });
}